import React from 'react'

import { observer } from 'mobx-react-lite';


const  Map = observer(() => {
  
 


  return (
    <div>Map
     
   
    </div>
  )
})

export default Map